<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Stack border="b">
					<Section>
						<Heading size="1" uppercase>Edison</Heading>
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12">
				<Stack width="100%" border="b">
					<Stack width="100%" justify="spaceBetween">
						<Link url="/portfolio/dallas" display="inline">
							<Stack height="60px" width="70px" justify="center" border="r">
								<Icon icon="arrow_left" size="large" color="tertiary" />
							</Stack>
						</Link>
						<Link url="/portfolio/havelock" display="inline">
							<Stack height="60px" width="70px" justify="center" border="l">
								<Icon icon="arrow_right" size="large" color="tertiary" />
							</Stack>
						</Link>
					</Stack>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 6@md">
				<Stack direction="column" align="left" width="100%" border="n r@md">
					<Section>
						<Browser image="theme-ar/edison1.jpg" />
					</Section>
					<Stack width="100%" border="b"></Stack>
					<Section size="large">
						<Stack direction="column" space="1">
							<Images width="100%" image="theme-ar/edison2.jpg" border />
						</Stack>
					</Section>
					<Section size="large"></Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 6@md">
				<Stack direction="column" align="left" width="100%" border="t n@md">
					<Stack width="100%">
						<Section>
							<Heading size="2">An informative site for partners and collaborators.</Heading>
							<Paragraph>As in all corporate domains, it is sometimes difficult to come up with a creative approach that does not undermine the professionalism. For the Edison theme, I wanted to provide this creative response that would allow a company to stand out and above all to have a brand image worthy of the innovative solutions they offer.</Paragraph>
							<Paragraph>I am committed to providing a creative response to areas which, by definition, do not lend themselves to it. For Edison Digital, the idea was to bring an ergonomics serving design, conveyed by a spacious page layout, and light micro-interactions to give a dynamic and different user-experience.</Paragraph>
							<Button url="/edison" label="GO TO WEBSITE" color="tertiary" newTab />
						</Section>
					</Stack>
					<Stack width="100%" border="b"></Stack>
					<Section>
						<Phone image="theme-ar/edison3.jpg" />
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12">
				<Stack border="b"></Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 4@md">
				<Stack width="100%">
					<Section>
						<Heading size="2" margin="none" uppercase>Design Elements</Heading>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 4@md">
				<Stack direction="column" align="left" width="100%" height="100%" border="t l@md">
					<Stack width="100%" border="b">
						<Section>
							<Heading size="5" margin="none" uppercase>Colors</Heading>
						</Section>
					</Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Color color="#4154F1" title="Brand Primary"></Color>
							<Color color="#1FC07D" title="Brand Secondary"></Color>
							<Color color="#E1A026" title="Brand Tertiary"></Color>
						</Stack>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 4@md">
				<Stack direction="column" align="left" justify="spaceBetween" width="100%" height="100%" border="t l@md">
					<Stack width="100%" border="b">
						<Section>
							<Heading size="5" margin="none" uppercase>Typography</Heading>
						</Section>
					</Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-ed"><Heading size="1" margin="none">Heading</Heading></Stage>
						</Stack>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-ed"><Heading size="3" margin="none">Accent</Heading></Stage>
						</Stack>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-ed"><Heading size="5" margin="none">Sub Header</Heading></Stage>
						</Stack>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-ed"><Paragraph margin="none">Body Copy</Paragraph></Stage>
						</Stack>
					</Section>
					<Stack width="100%"></Stack>
				</Stack>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/annarobbins/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme); 
  },
}

</script>

<style lang="scss">
</style>
